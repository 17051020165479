interface RadioFilterProp {
  id: string;
  onChange: (value: string) => void;
  options: {
    id: string;
    name: string;
    value: string;
  }[];
  value: string;
  name: string;
}

export const RadioFilter: React.FC<RadioFilterProp> = ({
  id,
  onChange,
  options,
  value,
  name,
}) => {
  return (
    <div className="mb-4">
      <h2 className="text-right mb-2 text-sm font-semibold">Time to Prepare</h2>
      <div className="flex flex-col">
        {options.map((option, index) => (
          <label
            key={index}
            htmlFor={`${option.name}-${option.id}`}
            className="flex items-center mb-2"
          >
            <input
              type="radio"
              id={`${option.name}-${option.id}`}
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
              className="mr-2"
            />
            {option.name}
          </label>
        ))}
        {value && (
          <p
            onClick={() => onChange('')}
            className="text-xs text-gray-400 underline"
          >
            Clear
          </p>
        )}
      </div>
    </div>
  );
};
