import RangeSlider from 'react-range-slider-input';

interface RangeFilterOption {
  min: number;
  max: number;
  value: number[];
  onChange: Function;
  name: string;
}
export const RangeFilter: React.FC<RangeFilterOption> = ({
  min,
  max,
  value,
  onChange,
  name,
}) => {
  return (
    <div className="mb-4">
      <label className="block mb-2 text-right text-sm font-semibold">
        Price
      </label>
      <RangeSlider
        min={min}
        max={max}
        step={500}
        id="range-filter"
        value={value}
        onInput={(_value: number[]) => onChange(_value)}
      />
      <div className="flex justify-between mt-2">
        <span className="font-semibold text-sm">XAF {value[0]}</span>
        <span className="font-semibold text-sm">XAF {value[1]}</span>
      </div>
    </div>
  );
};
