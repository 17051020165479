import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { Suspense } from 'react';
import { ScanLoader } from './components/ScanLoader';

function App() {
  return (
    <Suspense fallback={<ScanLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
<ScanLoader />;
