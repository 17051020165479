import { createSlice } from '@reduxjs/toolkit';
import { IRestaurantState } from './models';

const initialState: IRestaurantState = {
  categories: [],
  menu: [],
  restaurantInfo: null,
  restaurantInfoLoaded: false,
  categoriesLoaded: false,
  menuLoaded: false,
  activeMenuItem: null,
  activeMenuItemLoaded: false,
  activeCategory: null,
};

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    fetchCategories: (state, action) => {
      state.categories = state.categories.concat(action.payload);
      state.categoriesLoaded = true;
    },
    fetchMenu: (state, action) => {
      state.menu = action.payload;
      state.menuLoaded = true;
    },
    fetchRestaurantInfo: (state, action) => {
      state.restaurantInfo = action.payload;
      state.restaurantInfoLoaded = true;
    },
    fetchActiveMenuItem: (state, action) => {
      state.activeMenuItem = action.payload;
      state.activeMenuItemLoaded = true;
    },
    updateActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
  },
});

export const {
  fetchCategories,
  fetchMenu,
  fetchRestaurantInfo,
  fetchActiveMenuItem,
  updateActiveCategory,
} = restaurantSlice.actions;
export default restaurantSlice.reducer;
