import React from 'react';
import { Skeleton } from '../../../components';
import { ClockIcon } from '@heroicons/react/16/solid';
import { AddonSkeleton } from '../components/Addon/AddonSkeleton';

export const MenuDetailSkeleton = () => {
  return (
    <div className="bg-tertiary-100">
      <Skeleton width="100%" height="200px" />
      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <Skeleton width="120px" height="15px" />
          <div className="flex justify-between items-center">
            <Skeleton width="110px" height="15px" />
            <div className="flex items-center justify-between space-x-2 ml-2 text-xs rounded-md px-1 bg-gray-200 text-gray-600">
              <ClockIcon width={16} height={16} />
              <Skeleton width="80px" height="15px" />
            </div>
          </div>
        </div>
        <Skeleton width="90%" height="15px" />
        <br></br>
        <Skeleton width="80%" height="15px" />
        <br></br>
        <Skeleton width="60%" height="15px" />
      </div>
      <AddonSkeleton />
      <AddonSkeleton />
      <AddonSkeleton />
    </div>
  );
};
