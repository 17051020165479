import { IBranch } from '../../features/common/models';
import { IRestaurant } from '../../features/restaurant/models';

export const getRestaurantImageURL = (restaurant: IRestaurant): string => {
  if (restaurant.image) {
    return restaurant.image;
  } else if (restaurant.branch.image) {
    return restaurant.branch.image;
  } else if (restaurant.branch.business.image) {
    return restaurant.branch.business.image;
  }
  return '';
};

export const getRestaurantLogoURL = (restaurant: IRestaurant): string => {
  if (restaurant.logo) {
    return restaurant.logo;
  } else if (restaurant.branch.logo) {
    return restaurant.branch.logo;
  } else if (restaurant.branch.business.logo) {
    return restaurant.branch.business.logo;
  }
  return '';
};

export const getMenuPlaceholderImage = (categoryType: string): string => {
  if (categoryType === 'drink') {
    return '/images/drink-placeholder.png';
  } else if (categoryType === 'food') {
    return '/images/food-placeholder.jpg';
  }
  return '/images/drink-placeholder.png';
};
