import { createBrowserRouter, Navigate } from 'react-router-dom';
import Home from './pages/restaurant/Home';
import Restaurant from './pages/restaurant/Restaurant';
import { lazy } from 'react';
import { MenuDetail } from './pages/restaurant/MenuDetail';

const Business = lazy(() => import('./pages/business/Business'));
const BranchHome = lazy(() => import('./pages/business/Home'));

export const router = createBrowserRouter([
  {
    path: '',
    element: <Navigate to={'/restaurant'} />,
  },
  {
    path: '/restaurant/:restaurantId',
    element: <Restaurant />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: ':menuItemId',
        element: <MenuDetail />,
      },
    ],
  },
  {
    path: '/business',
    element: <Business />,
    children: [
      {
        path: 'branch/:branchId',
        element: <BranchHome />,
      },
    ],
  },
]);
