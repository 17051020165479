import { useEffect } from 'react';
import './MenuDetail.css';
import { ImageSlider } from '../../../components';
import { Addon } from '../components/Addon';
import { useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../hooks/useTypedSelector';
import { ClockIcon } from '@heroicons/react/16/solid';
import {
  getRestaurantInfo,
  getRestaurantMenuItem,
} from '../../../api/restaurantService';
import {
  fetchActiveMenuItem,
  fetchRestaurantInfo,
} from '../../../features/restaurant/restaurantSlice';
import { MenuDetailSkeleton } from './MenuDetailSkeleton';
import { get } from 'http';
import { getMenuPlaceholderImage } from '../../../common/utils/restaurantUtils';

export const MenuDetail = () => {
  const {
    activeMenuItem,
    activeMenuItemLoaded,
    restaurantInfo,
    restaurantInfoLoaded,
    activeCategory,
  } = useAppSelector((state) => state.restaurant);
  const { restaurantId, menuItemId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!activeMenuItemLoaded) {
      const fetchMenuItemData = async () => {
        const menuItem = await getRestaurantMenuItem(
          restaurantId as string,
          menuItemId as string
        );
        dispatch(fetchActiveMenuItem(menuItem));
      };
      fetchMenuItemData();
    }
  }, []);
  useEffect(() => {
    if (!restaurantInfoLoaded) {
      const fetchRestaurantInfoData = async () => {
        if (restaurantId) {
          const _restaurantInfo = await getRestaurantInfo(restaurantId);
          dispatch(fetchRestaurantInfo(_restaurantInfo));
        }
      };
      fetchRestaurantInfoData();
    }
  }, [restaurantId]);

  return (
    <>
      {activeMenuItemLoaded ? (
        <div className="bg-tertiary-100">
          <ImageSlider
            fallback={getMenuPlaceholderImage(activeCategory?.type as string)}
            alt={activeMenuItem?.name as string}
            images={activeMenuItem?.images ?? []}
          />
          <div className="p-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-base font-semibold">
                {activeMenuItem?.name}
              </h2>
              <div className="flex justify-between items-center">
                <span className="text-base font-semibold">
                  {restaurantInfo?.branch.currency}{' '}
                  {activeMenuItem?.unitPricing.price}
                </span>
                {activeMenuItem?.timeToPrepareMins && (
                  <div className="flex items-center justify-between space-x-2 ml-2 text-xs rounded-md px-1 bg-gray-200 text-gray-600">
                    <ClockIcon width={16} height={16} />
                    <span>{activeMenuItem?.timeToPrepareMins}mins</span>
                  </div>
                )}
              </div>
            </div>

            <p className="text-gray-600 text-sm">
              {activeMenuItem?.longDescription}
            </p>
          </div>
          <Addon
            title="Ingredients"
            addons={
              activeMenuItem?.ingredients.map((ingredient) => ({
                name: ingredient.name,
                image: ingredient.image,
                price: 0,
              })) ?? []
            }
          />
          <Addon
            title="Complements"
            addons={
              activeMenuItem?.complements.map((complement) => ({
                name: complement.complement.name,
                image: complement.complement.image,
                price: complement.extraCharge,
              })) ?? []
            }
          />
          <Addon
            title="Addons"
            addons={
              activeMenuItem?.addons.map((addon) => ({
                name: addon.name,
                image: addon.image,
                price: addon.extraCharge,
              })) ?? []
            }
          />
          <Addon
            title="Toppins"
            addons={
              activeMenuItem?.toppins.map((toppin) => ({
                name: toppin.name,
                image: toppin.image,
                price: toppin.extraCharge,
              })) ?? []
            }
          />
        </div>
      ) : (
        <MenuDetailSkeleton />
      )}
    </>
  );
};
