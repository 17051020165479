export const endpoint = {
  CORE: {
    BRANCH_INFO: (branchId: string) => `/api/v1/core/branch/${branchId}/info`,
  },
  RESTAURANT: {
    RESTAURANT_INFO: (restaurantId: string) =>
      `/api/v1/restaurant/${restaurantId}/info`,
    GET_CATEGORIES: (restaurantId: string) =>
      `/api/v1/restaurant/${restaurantId}/categories?all=true`,
    GET_MENU: (restaurantId: string) =>
      `/api/v1/restaurant/${restaurantId}/menu?all=true`,
    GET_MENU_ITEM: (restaurantId: string, menuItemId: string) =>
      `/api/v1/restaurant/${restaurantId}/menu/${menuItemId}`,
  },
};
