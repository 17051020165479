import './Addon.css';

interface AddonProp {
  addons: { name: string; image: string; price: number }[];
  title: string;
}

export const Addon: React.FC<AddonProp> = ({ addons, title }) => {
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-base font-semibold line-before line-before__secondary">
          {title}
        </h3>
      </div>
      {addons.length > 0 ? (
        <div className="flex space-x-4 overflow-x-auto pb-4">
          {addons.map((addon, index) => (
            <div
              key={index}
              className="text-center bg-white p-2 rounded-full w-12"
            >
              <img
                src={addon.image}
                alt={addon.name}
                className="w-8 h-8 rounded-full object-cover mb-2 mx-auto"
              />
              <p className="text-xs break-words">{addon.name}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-sm text-gray-400">
          No {title} available
        </p>
      )}
    </div>
  );
};
