import React, { act, useEffect, useState } from 'react';
import { ICategory } from '../../../../features/restaurant/models';
import './Categories.css';

interface CategoriesProps {
  categories: ICategory[];
  activeCategoryId: number;
  onChangeActiveCategory?: (categoryId: number) => void;
}

const Categories: React.FC<CategoriesProps> = React.memo(
  ({ categories, onChangeActiveCategory, activeCategoryId }) => {
    const [activeCategory, setActiveCategory] = useState<number>(-1);
    useEffect(() => {
      changeActiveCategory(categories.length > 0 ? categories[0].id : -1);
    }, [categories]);

    useEffect(() => {
      if (activeCategoryId) {
        setActiveCategory(activeCategoryId);
      }
      window.location.hash = `category-${activeCategoryId}`;
    }, [activeCategoryId]);

    const changeActiveCategory = (categoryId: number) => {
      setActiveCategory(categoryId);
      onChangeActiveCategory && onChangeActiveCategory(categoryId);
    };

    return (
      <div className="flex text-nowrap overflow-x-auto pt-4 no-scrollbar category-list-wrapper">
        {categories.map((category, index) => (
          <div
            key={index}
            onClick={() => changeActiveCategory(category.id)}
            className={`category-list-wrapper__item px-2 pb-2 text-xs font-semibold ${
              category.id === activeCategory
                ? 'sticky left-0 right-0 border-b-4 border-b-secondary-100'
                : ''
            }`}
          >
            {category.name}
          </div>
        ))}
      </div>
    );
  }
);

export { Categories };
