import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import './SearchBar.css';
import { ChangeEvent, ChangeEventHandler, useState, forwardRef } from 'react';

interface SearchBarProp {
  onFilterClick: Function;
  isFilterActive: boolean;
  onSearch: (value: string) => void;
}

export const SearchBar = forwardRef<HTMLDivElement, SearchBarProp>(
  (props, ref) => {
    const [value, setValue] = useState('');
    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        props.onSearch(value);
      }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    const clearSearch = () => {
      setValue('');
      props.onSearch('');
    };

    return (
      <div
        ref={ref}
        className="flex items-center h-10 rounded-3xl p-3 mt-4 search-wrapper"
      >
        <MagnifyingGlassIcon className="w-6 h-6 text-gray-400 font-thin mr-2" />
        <input
          type="text"
          placeholder="Search your Favorite food"
          onKeyUp={handleKeyPress}
          onChange={handleChange}
          value={value || ''}
          className="bg-transparent outline-none w-full text-gray-400 text-sm"
        />
        {value && (
          <XMarkIcon
            className="w-6 h-6 text-gray-400 font-thin mr-3"
            onClick={clearSearch}
          />
        )}
        <AdjustmentsHorizontalIcon
          className="w-6 h-6 text-gray-400 font-thin "
          onClick={() => props.onFilterClick()}
        />

        {/* Assuming FontAwesome for the icon */}
      </div>
    );
  }
);

export default SearchBar;
