import { useEffect, useState, forwardRef } from 'react';
import { IMenuItem } from '../../../../features/restaurant/models';
import './Menu.css';
import { ClockIcon } from '@heroicons/react/16/solid';
import { ImageWithFallback } from '../../../../components';
import { getMenuPlaceholderImage } from '../../../../common/utils/restaurantUtils';

interface MenuProps {
  menu: IMenuItem[];
  currency: string;
  categoryName: string;
  categoryId: string;
  categoryType: string;
  activeCategoryId: number;
  onClickMenuItem?: (menuItem: IMenuItem) => void;
  setIsAutoScrolling: (isScrolling: boolean) => void;
}

function scrollToWithCallback(scrollTargetY: number, callback: Function) {
  let previousScrollY = window.scrollY || document.documentElement.scrollTop;
  window.scrollTo({
    top: scrollTargetY,
    behavior: 'smooth',
  });

  const checkIfDone = () => {
    const newScrollY = document.documentElement.scrollTop;
    if (previousScrollY === newScrollY) {
      callback();
    } else {
      previousScrollY = newScrollY;
      setTimeout(checkIfDone, 100);
    }
  };

  setTimeout(checkIfDone, 100);
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  (
    {
      menu = [],
      currency,
      categoryName,
      categoryType,
      categoryId,
      activeCategoryId,
      setIsAutoScrolling,
      onClickMenuItem,
    },
    ref
  ) => {
    useEffect(() => {
      if (+categoryId === activeCategoryId) {
        const element = document.getElementById(`category-${categoryId}`);
        if (element) {
          // setIsAutoScrolling(true);
          // const offsetTop = element.offsetTop - 248;
          // scrollToWithCallback(offsetTop, () => {
          //   setIsAutoScrolling(false);
          // });
        }
      }
    }, [activeCategoryId]);

    return (
      <div
        ref={ref}
        data-category-header
        id={`category-${categoryId}`}
        data-category-id={categoryId}
      >
        <h3 className="my-4 font-semibold text-white w-fit px-1 bg-primary-100 mx-5 text-right">
          {categoryName}
        </h3>
        <div className="px-5 pb-2 bg-white">
          <div className="grid grid-cols-2 gap-4 items-stretch pt-2">
            {menu.map((menuItem, index) => (
              <div
                onClick={() => onClickMenuItem && onClickMenuItem(menuItem)}
                key={index}
                className="relative menu-item-wrapper"
              >
                {/* Image Wrapper */}
                <div className="menu-item-wrapper__image rounded-full mx-auto absolute left-7 z-10">
                  {/* <img
                  src={menuItem.images[0]}
                  alt={menuItem.name}
                  className="w-24 h-24 mx-auto rounded-full object-cover bg-gray-300"
                /> */}
                  <ImageWithFallback
                    src={menuItem.images[0]}
                    alt={menuItem.name}
                    className="w-24 h-24 mx-auto rounded-full object-cover bg-gray-300"
                    fallback={getMenuPlaceholderImage(categoryType)}
                  />
                </div>
                {menuItem.timeToPrepareMins ? (
                  <div className="rounded-md absolute right-0 top-2 p-1 flex  bg-secondary-100 text-white text-xs z-10">
                    <ClockIcon width={16} height={16} />
                    <span className="pl-1">
                      {menuItem.timeToPrepareMins}mins
                    </span>
                  </div>
                ) : (
                  <></>
                )}

                {/* Card */}
                <div className="menu-item-wrapper__card bg-white rounded-lg shadow-lg px-4 pt-6 relative h-28 mt-20 flex justify-between flex-col  ">
                  <div>
                    <h3 className="font-semibold line-clamp-2 text-sm">
                      {menuItem.name}
                    </h3>
                    <p className="text-xs text-gray-500 mb-2 text-ellipsis text-nowrap overflow-hidden">
                      {menuItem.shortDescription}
                    </p>
                  </div>

                  {/* Pricing Section */}
                  <div className="flex justify-between items-center">
                    <p className="text-base font-semibold">
                      {currency} {menuItem.unitPricing.price}
                    </p>
                    {menuItem.unitPricing.unit && (
                      <p className="text-gray-500 text-xs">
                        per {menuItem.unitPricing.unit}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export { Menu };
