import loaderIcon from '../../assets/svg/loader.svg';

export const ScanLoader = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{ width: '48px', height: '48px' }}
        src={loaderIcon}
        alt="Animated SVG"
      />
    </div>
  );
};
