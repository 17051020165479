export const Logo = ({
  logoUrl,
  alt,
  height,
  width,
}: {
  logoUrl: string;
  alt: string;
  height?: string;
  width?: string;
}) => {
  return (
    <img
      className="object-cover"
      style={{
        height: height ? height : '48px',
        width: width ? width : '48px',
      }}
      src={logoUrl}
      alt={alt}
    />
  );
};
