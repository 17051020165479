import { createSlice } from '@reduxjs/toolkit';
import { IBranchState } from './models';

const initialState: IBranchState = {
  branchInfo: null,
  branchInfoLoaded: false,
};

export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    fetchBranchInfo: (state, action) => {
      state.branchInfo = action.payload;
      state.branchInfoLoaded = true;
    },
  },
});

export const { fetchBranchInfo } = branchSlice.actions;
export default branchSlice.reducer;
