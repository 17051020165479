import axios, { AxiosResponse } from 'axios';
import { IApiResponse } from './model';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const get = async <T>(url: string): Promise<T> => {
  const response: AxiosResponse<IApiResponse<T>> = await axios.get(
    API_BASE_URL + url
  );
  return response.data.data;
};
