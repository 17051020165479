import { Outlet } from 'react-router-dom';

const Restaurant = () => {
  return (
    <>
      <div className="font-body pb-10">
        <Outlet />
      </div>
    </>
  );
};

export default Restaurant;
