import { ReactNode } from 'react';

interface ModalProp {
  children?: ReactNode;
}

export const Modal: React.FC<ModalProp> = ({ children }) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-dark-300 bg-opacity-50 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex justify-center items-center">
        {children}
      </div>
    </div>
  );
};
