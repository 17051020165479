import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Modal, SearchBar, Skeleton } from '../../../components';
import { Categories, Menu } from '../components';
import './Home.css';
import {
  getRestaurantCategories,
  getRestaurantInfo,
  getRestaurantMenu,
} from '../../../api/restaurantService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../hooks/useTypedSelector';
import {
  ICategory,
  IMenuItem,
  IRestaurant,
} from '../../../features/restaurant/models';
import { RootState } from '../../../app/store';
import {
  fetchActiveMenuItem,
  fetchCategories,
  fetchMenu,
  fetchRestaurantInfo,
  updateActiveCategory,
} from '../../../features/restaurant/restaurantSlice';
import { ScanLoader } from '../../../components/ScanLoader';
import { Logo } from '../../../components/Logo';
import {
  getRestaurantImageURL,
  getRestaurantLogoURL,
} from '../../../common/utils/restaurantUtils';
import { MenuSkeleton } from '../components/Menu/Skeleton';
import { useQuery } from '../../../common/utils/utils';
import { Filter, FilterOption } from '../components/Filter';

let scrollTimeout: NodeJS.Timeout | undefined;

const Home = () => {
  const { restaurantId } = useParams<{ restaurantId: string }>();
  const query = useQuery();
  const [table] = useState(query.get('table'));
  const [room] = useState(query.get('room'));
  const [searchBarStickyTop, setSearchBarStickyTop] = useState(0);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [activeCategory, setActiveCategory] = useState<number>(-1);
  const [showFilter, setShowFilter] = useState(false);
  const isAutoScrollingMenuRef = useRef(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const sectionsRef = useRef<HTMLDivElement[]>([]);
  const [formattedMenu, setFormattedMenu] = useState<
    Map<string, { name: string; type: string; menu: IMenuItem[] }>
  >(new Map());
  const [filteredFormattedMenu, setFilteredFormattedMenu] = useState<
    Map<string, { name: string; type: string; menu: IMenuItem[] }>
  >(new Map());
  const [formattedCategories, setFormattedCategories] = useState<
    Map<string, ICategory>
  >(new Map());
  const [filterData, setFilterData] = useState<Record<string, any>>({});
  const {
    restaurantInfo,
    restaurantInfoLoaded,
    menu,
    menuLoaded,
    categories,
    categoriesLoaded,
  } = useAppSelector((state: RootState) => state.restaurant);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filterOptions, setFilterOptions] = useState<FilterOption[]>([
    {
      id: '1',
      type: 'range',
      default: [0, 10000],
      title: 'Price',
      name: 'price',
    },
    {
      id: '2',
      type: 'radio',
      default: '',
      title: 'Time to Prepare',
      name: 'time_to_prepare',
      options: [
        {
          id: '10',
          name: '< 10mins',
          value: '10',
        },
        {
          id: '20',
          name: '< 20mins',
          value: '20',
        },
        {
          id: '60',
          name: '< 1hr',
          value: '60',
        },
      ],
    },
  ]);

  useEffect(() => {
    const _filterData: Record<string, any> = {};
    filterOptions.forEach((option) => {
      _filterData[option.id] = option.default;
    });
    setFilterData(_filterData);
  }, [filterOptions]);

  // useEffect(() => {
  //   clearTimeout(scrollTimeout);
  //   window.addEventListener('scroll', () => {
  //     scrollTimeout = setTimeout(() => {
  //       if (!isAutoScrollingMenuRef.current) {
  //         const headers = document.querySelectorAll('[data-category-header]');
  //         let currentCategoryId = activeCategory;
  //         headers.forEach((header) => {
  //           const rect = header.getBoundingClientRect();
  //           const offsetTop = rect.top;
  //           if (offsetTop >= 0 && offsetTop < window.innerHeight / 2) {
  //             const category = header.getAttribute('data-category-id');
  //             if (category && +category !== currentCategoryId) {
  //               currentCategoryId = +category;
  //               setActiveCategory(currentCategoryId);
  //             }
  //           }
  //         });
  //       }
  //     }, 200);
  //   });
  // }, []);

  useEffect(() => {
    if (!restaurantInfoLoaded) {
      const fetchRestaurantInfoData = async () => {
        if (restaurantId) {
          const _restaurantInfo = await getRestaurantInfo(restaurantId);
          dispatch(fetchRestaurantInfo(_restaurantInfo));
        }
      };
      fetchRestaurantInfoData();
    }
  }, [restaurantId]);

  const setIsAutoScrollingMenu = (isScrolling: boolean) => {
    isAutoScrollingMenuRef.current = isScrolling;
  };

  const changeActiveCategory = useCallback((categoryId: number) => {
    setActiveCategory(categoryId);
  }, []);

  useEffect(() => {
    if (!menuLoaded && !categoriesLoaded) {
      const fetchRestaurantData = async () => {
        if (restaurantId) {
          Promise.all([
            getRestaurantCategories(restaurantId),
            getRestaurantMenu(restaurantId),
          ]).then(([_categories, _menu]) => {
            const _formattedCategories = formatCategory(_categories);
            const _formattedMenu = formatMenuForView(
              _menu,
              _formattedCategories
            );
            setFilteredFormattedMenu(new Map([..._formattedMenu]));
            dispatch(fetchCategories(_categories));
            dispatch(fetchMenu(_menu));
          });
        }
      };
      fetchRestaurantData();
    } else {
      formatMenuForView(menu, formatCategory(categories));
      performFiltering();
    }
  }, [restaurantId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries.filter((entry) => entry.isIntersecting);
        visibleEntries.sort(
          (a, b) => a.boundingClientRect.top - b.boundingClientRect.top
        );
        if (visibleEntries.length > 0) {
          const id = visibleEntries[0].target.getAttribute('id');
          if (id) {
            window.history.replaceState(null, '', `#${id}`);
            setActiveCategory(+id.replace('category-', ''));
          }
        }
      },
      { threshold: 0.3 }
    );
    Array.from(sectionsRef.current).forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, [filteredFormattedMenu]);

  useEffect(() => {
    performFiltering();
  }, [filterData]);

  useEffect(() => {
    let maxPrice = 0;
    menu.forEach((menuItem) => {
      if (menuItem.unitPricing.price > maxPrice) {
        maxPrice = menuItem.unitPricing.price;
      }
    });
    const _filterOptions = [...filterOptions];
    setFilterOptions([
      {
        ..._filterOptions[0],
        type: 'range',
        default: [0, maxPrice],
      },
      { ..._filterOptions[1] },
    ]);
  }, [menu]);

  const performFiltering = () => {
    setFilteredFormattedMenu(new Map([...formattedMenu]));
    const _filteredFormattedMenuRecord: Map<
      string,
      { name: string; type: string; menu: IMenuItem[] }
    > = new Map();
    Array.from(formattedMenu.keys()).forEach((categoryId) => {
      _filteredFormattedMenuRecord.set(String(categoryId), {
        name: formattedMenu.get(categoryId)?.name || '',
        type: formattedMenu.get(categoryId)?.type || '',
        menu: [
          ...(formattedMenu.get(String(categoryId))?.menu || []).filter(
            (menuItem: IMenuItem) => {
              let [price, timeToPrepare, search] = [true, true, true];
              if (filterData['1']) {
                const _filterData = filterData['1'] as number[];
                price =
                  menuItem.unitPricing.price <= _filterData[1] &&
                  menuItem.unitPricing.price >= _filterData[0];
              }
              if (filterData['2']) {
                if (menuItem.timeToPrepareMins) {
                  const _filterData = filterData['2'] as string;
                  timeToPrepare = menuItem.timeToPrepareMins < +_filterData;
                } else {
                  timeToPrepare = true;
                }
              }
              if (isSearch) {
                search = menuItem.name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase());
                setIsSearch(false);
              }
              return price && timeToPrepare && search;
            }
          ),
        ],
      });
    });
    setFilteredFormattedMenu(new Map([..._filteredFormattedMenuRecord]));
  };
  const categoriesWithProducts = useMemo(() => {
    return Array.from(filteredFormattedMenu.keys() || [])
      .filter(
        (categoryId) =>
          (filteredFormattedMenu.get(String(categoryId))?.menu.length || 0) > 0
      )
      .map(
        (categoryId) => formattedCategories.get(String(categoryId)) as ICategory
      );
  }, [filteredFormattedMenu, formattedCategories]);

  useEffect(() => {
    let stickyTopPosition = 0;
    if (searchBarRef.current && headerRef.current) {
      const searchBarWidth = searchBarRef.current.clientHeight;
      const searchBarMarginTop = +window
        .getComputedStyle(searchBarRef.current)
        .marginTop.replace('px', '');
      const headerHeight = headerRef.current?.clientHeight;
      const headerPaddingBottom = +window
        .getComputedStyle(headerRef.current)
        .paddingBottom.replace('px', '');

      stickyTopPosition =
        -1 *
        (headerHeight -
          searchBarWidth -
          searchBarMarginTop -
          headerPaddingBottom);
    }
    setSearchBarStickyTop(stickyTopPosition);
  }, [searchBarRef.current, headerRef.current]);

  const setSectionRef = (el: HTMLDivElement | null, index: number) => {
    if (el) {
      sectionsRef.current[index] = el; // Duplicate the array and assign the element
    }
  };

  const handleFilter = (fData: Record<string, any>) => {
    setFilterData({ ...fData });
    setShowFilter(false);
  };

  const formatCategory = (_categories: ICategory[]): Map<string, ICategory> => {
    const _formattedCategories: Map<string, ICategory> = new Map();
    const sortedCategories = [..._categories].sort(
      (categoryA, categoryB) => categoryA.priority - categoryB.priority
    );
    sortedCategories.forEach((_category) => {
      _formattedCategories.set(String(_category.id), _category);
    });
    setFormattedCategories(_formattedCategories);
    return _formattedCategories;
  };

  const formatMenuForView = (
    menu: IMenuItem[],
    _formattedCategories: Map<string, ICategory>
  ): Map<
    string,
    { name: string; type: string; menu: IMenuItem[]; categoryPriority: number }
  > => {
    let _formattedMenu: Map<
      string,
      {
        name: string;
        type: string;
        menu: IMenuItem[];
        categoryPriority: number;
      }
    > = new Map();
    menu.forEach((menuItem) => {
      menuItem.categories.forEach((_category) => {
        if (_formattedMenu.has(String(_category))) {
          _formattedMenu.set(String(_category), {
            name: _formattedMenu.get(String(_category))?.name || '', // Provide a default value
            type: _formattedMenu.get(String(_category))?.type || '', // Provide a default value
            menu: (() => {
              const currentMenu =
                _formattedMenu.get(String(_category))?.menu || [];
              currentMenu.push(menuItem); // Push the new item
              return currentMenu; // Return the updated menu
            })(), // Provide a default value
            categoryPriority:
              _formattedMenu.get(String(_category))?.categoryPriority || 0, // Provide a default value
          });
        } else {
          _formattedMenu.set(String(_category), {
            name: _formattedCategories.get(String(_category))?.name || '',
            type: _formattedCategories.get(String(_category))?.type || '',
            menu: [menuItem],
            categoryPriority:
              _formattedCategories.get(String(_category))?.priority || 0, // Default priority
          });
        }
      });
    });
    _formattedMenu = Array.from(_formattedMenu.entries())
      .sort(([categoryIdA, objA], [categoryIdB, objB]) => {
        return objA.categoryPriority - objB.categoryPriority;
      })
      .reduce((acc, [key, value]) => {
        acc.set(key, value);
        return acc;
      }, new Map() as Map<string, { name: string; type: string; menu: IMenuItem[]; categoryPriority: number }>);
    setFormattedMenu(_formattedMenu);
    return _formattedMenu;
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setIsSearch(true);
    handleFilter(filterData);
  };

  const handleFilterClick = () => {
    setShowFilter(true);
  };

  return (
    <>
      {restaurantInfoLoaded ? (
        <>
          <div
            ref={headerRef}
            style={{
              background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${getRestaurantImageURL(
                restaurantInfo as IRestaurant
              )}') no-repeat center center / contain`,
              top: searchBarStickyTop,
              position: searchBarStickyTop !== 0 ? 'sticky' : 'relative',
            }}
            className="header px-5 py-6"
          >
            <div className="flex justify-between">
              <p className="intro">
                Get Your <b>BEST FOOD</b>
                <br />
                FROM <b>{restaurantInfo?.name}</b>
              </p>
              <Logo
                logoUrl={getRestaurantLogoURL(restaurantInfo as IRestaurant)}
                alt={restaurantInfo?.name as string}
              />
            </div>
            {(room || table) && (
              <p className="mt-4 bg-primary-100 rounded-full w-fit px-4 py-1">
                {table && <b>TABLE - {table}</b>}
                {room && <b>ROOM - {room}</b>}
              </p>
            )}
            <SearchBar
              isFilterActive={Object.keys(filterData).length > 0}
              ref={searchBarRef}
              onSearch={handleSearch}
              onFilterClick={handleFilterClick}
            />
          </div>

          {categoriesLoaded ? (
            <Categories
              activeCategoryId={activeCategory}
              categories={categoriesWithProducts}
              onChangeActiveCategory={changeActiveCategory}
            />
          ) : (
            <div className="grid grid-cols-4 gap-4 pt-4">
              <Skeleton width="100%" height="25px" />
              <Skeleton width="100%" height="25px" />
              <Skeleton width="100%" height="25px" />
              <Skeleton width="100%" height="25px" />
            </div>
          )}
          {menuLoaded ? (
            <div className="">
              {Array.from(filteredFormattedMenu.keys()).map(
                (categoryId, index) => {
                  if (
                    filteredFormattedMenu.get(categoryId)?.menu.length ||
                    0 > 0
                  ) {
                    return (
                      <Menu
                        key={categoryId}
                        categoryName={
                          filteredFormattedMenu.get(categoryId)?.name || ''
                        }
                        categoryId={categoryId}
                        categoryType={
                          filteredFormattedMenu.get(categoryId)?.type || ''
                        }
                        activeCategoryId={activeCategory}
                        setIsAutoScrolling={(isScrolling) =>
                          setIsAutoScrollingMenu(isScrolling)
                        }
                        currency={(
                          restaurantInfo?.branch.currency as string
                        ).toUpperCase()}
                        menu={filteredFormattedMenu.get(categoryId)?.menu || []}
                        onClickMenuItem={(menuItem: IMenuItem) => {
                          dispatch(fetchActiveMenuItem(menuItem));
                          dispatch(
                            updateActiveCategory(
                              formattedCategories.get(String(categoryId))
                            )
                          );
                          navigate(`${menuItem.id}`);
                        }}
                        ref={(el: HTMLDivElement) => setSectionRef(el, index)}
                      ></Menu>
                    );
                  }
                }
              )}

              {!Array.from(filteredFormattedMenu.values()).find(
                (menu) => menu.menu.length > 0
              ) &&
                menuLoaded &&
                categoriesLoaded && (
                  <p className="text-center text-sm text-gray-500">
                    No items match your search
                  </p>
                )}
              {showFilter && (
                <Modal>
                  <Filter
                    onCancel={() => setShowFilter(false)}
                    setFilterData={setFilterData}
                    onSave={(filterData: Record<string, any>) =>
                      handleFilter(filterData)
                    }
                    filterData={filterData}
                    options={filterOptions}
                  />
                </Modal>
              )}
            </div>
          ) : (
            <MenuSkeleton />
          )}
        </>
      ) : (
        <ScanLoader />
      )}
    </>
  );
};

export default Home;
