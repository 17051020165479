import React, { useEffect, useState } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './Filter.css';
import { time } from 'console';
import { FilterOption } from './model';
import { RangeFilter } from './RangeFilter';
import { RadioFilter } from './RadioFilter';

interface FilterProp {
  onCancel?: Function;
  onSave?: Function;
  options: FilterOption[];
  filterData: Record<string, any>;
  setFilterData: (filterData: Record<string, any>) => void;
}

export const Filter: React.FC<FilterProp> = ({
  onSave,
  onCancel,
  options,
  filterData,
  setFilterData,
}) => {
  const [fData, setFData] = useState({ ...filterData });
  const handleChange = (id: string, value: any) => {
    setFData({
      ...fData,
      [id]: value,
    });
  };

  return (
    <div className="p-4 bg-white rounded shadow fixed w-4/5">
      <h2 className="text-lg font-bold mb-4">Filter Menu</h2>
      {options.map((option, index) => {
        if (option.type === 'range') {
          return (
            <div key={index}>
              <RangeFilter
                min={option.default[0]}
                max={option.default[1]}
                name={option.name}
                value={fData[option.id]}
                onChange={(value: number[]) => handleChange(option.id, value)}
              />
              <div className="line bg-gray-300 my-4"></div>
            </div>
          );
        } else if (option.type === 'radio') {
          return (
            <div key={index}>
              <RadioFilter
                id={option.id}
                name={option.name}
                onChange={(value: string) => handleChange(option.id, value)}
                options={option.options}
                value={fData[option.id]}
              />
            </div>
          );
        }
        return <></>;
      })}
      <div className="line bg-gray-300 my-4"></div>
      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          onClick={() => onSave && onSave(fData)}
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-primary-100 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
        >
          Save
        </button>
        <button
          onClick={() => onCancel && onCancel()}
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
