import { Skeleton } from '../../../../components';

export const AddonSkeleton = () => {
  const addons = [1, 2, 3];
  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-2">
        <Skeleton width="60px" height="15px" />
      </div>
      <div className="grid grid-cols-4 gap-4 pb-4">
        {addons.map((addon, index) => (
          <div
            key={index}
            className="text-center flex flex-col items-center bg-white p-2 rounded-full"
          >
            <Skeleton width="2rem" height="2rem" variant="circle" />
            <br></br>
            <Skeleton width="30px" height="15px" />
          </div>
        ))}
      </div>
    </div>
  );
};
