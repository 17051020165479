import { Skeleton } from '../../../../components';
import './Menu.css';

export const MenuSkeleton = () => {
  const menu = [1, 2, 3, 4, 5, 6];
  return (
    <>
      <h3 className="mt-4 px-1 mx-5">
        <Skeleton width="150px" height="15px" />
      </h3>
      <div className="px-5 pb-2 bg-white">
        <div className="grid grid-cols-2 gap-4 mt-4 items-stretch">
          {menu.map((menuItem, index) => (
            <div key={index} className="relative menu-item-wrapper">
              <div className="menu-item-wrapper__image rounded-full mx-auto absolute left-7 z-10">
                <div className="mx-auto">
                  <Skeleton width="6rem" height="6rem" variant="circle" />
                </div>
              </div>

              {/* Card */}
              <div className="menu-item-wrapper__card bg-white rounded-lg shadow-lg px-4 pt-6 relative h-28 mt-20 flex justify-between flex-col  ">
                <div>
                  <h3 className="font-semibold line-clamp-2 text-sm">
                    <Skeleton width="100px" height="15px" />
                  </h3>
                  <p className="text-xs text-gray-500 mb-2 text-ellipsis text-nowrap overflow-hidden">
                    <Skeleton width="180px" height="15px" />
                  </p>
                </div>

                {/* Pricing Section */}
                <div className="flex justify-between items-center">
                  <p className="text-base font-semibold">
                    <Skeleton width="90px" height="15px" />
                  </p>
                  <p className="text-gray-500 text-xs">
                    <Skeleton width="70px" height="15px" />
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
