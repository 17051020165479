import { useEffect, useState } from 'react';
import './ImageSlider.css';
import { ImageWithFallback } from '../ImageWithFallback';

interface ImageSliderProp {
  images: string[];
  alt: string;
  fallback: string;
}

export const ImageSlider: React.FC<ImageSliderProp> = ({
  images,
  alt,
  fallback,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <div className="relative w-full h-64 overflow-hidden">
      {images.length ? (
        images.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transition-opacity duration-1000 ease-in-out ${
              index === currentIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            {/* <img src={image} alt="Slide" className="w-full h-full object-cover" /> */}
            <ImageWithFallback
              src={image}
              alt={alt}
              fallback={fallback}
              className="w-full h-full object-cover"
            />
          </div>
        ))
      ) : (
        <ImageWithFallback src={fallback} alt={alt} fallback={fallback} />
      )}
    </div>
  );
};
