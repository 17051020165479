import axios from 'axios';
import * as api from '../api/http';
import { endpoint } from './endpoint';
import {
  ICategory,
  IMenuItem,
  IRestaurant,
} from '../features/restaurant/models';

export const getRestaurantInfo = async (id: string): Promise<IRestaurant> => {
  const restaurant: IRestaurant = await api.get<IRestaurant>(
    endpoint.RESTAURANT.RESTAURANT_INFO(id)
  );
  return restaurant;
};

export const getRestaurantCategories = async (
  id: string
): Promise<ICategory[]> => {
  const categories: ICategory[] = await api.get<ICategory[]>(
    endpoint.RESTAURANT.GET_CATEGORIES(id)
  );
  return categories;
};

export const getRestaurantMenu = async (id: string): Promise<IMenuItem[]> => {
  const menu: IMenuItem[] = await api.get<IMenuItem[]>(
    endpoint.RESTAURANT.GET_MENU(id)
  );
  return menu;
};

export const getRestaurantMenuItem = async (
  restaurantId: string,
  menuItemId: string
): Promise<IMenuItem> => {
  const menuItem: IMenuItem = await api.get<IMenuItem>(
    endpoint.RESTAURANT.GET_MENU_ITEM(restaurantId, menuItemId)
  );
  return menuItem;
};
