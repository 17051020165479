import './Skeleton.css';

interface SkeletonProps {
  width: string;
  height: string;
  variant?: string;
}
export const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  variant,
}) => {
  const style = {
    width,
    height,
  };
  return <span className={`skeleton ${variant}`} style={style}></span>;
};
