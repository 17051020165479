import { configureStore } from '@reduxjs/toolkit';
import restaurantReducer from '../features/restaurant/restaurantSlice';
import branchSlice from '../features/business/branchSlice';

export const store = configureStore({
  reducer: {
    restaurant: restaurantReducer,
    branch: branchSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
